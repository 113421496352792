<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("containers", pageName, pageName) }}
        </div>
        <template v-slot:right>
          <router-link
            v-if="can($route.meta.middlewares.permission, 'create')"
            :to="{
              name: addRouteName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('global.search.search')"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfContainers && listOfContainers.length"
    >
      <li
        class="clebex-item-section-item"
        :class="{ 'full-right-underline': !editing }"
        v-for="container in listOfContainers"
        :key="container.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="editContainer(container)"
          v-if="!editing"
        >
          <span class="label">
            <span class="highlight">{{ container.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information"></icon>
            </span>
          </span>
        </button>
        <div class="clebex-item-content-wrapper" v-else>
          <div class="checkbox tiny alt block">
            <input
              type="checkbox"
              :id="`resource-type${container.id}`"
              name="user"
              :selected="selectedContainers.includes(container.id)"
              :value="container.id"
              @change="selectContainer(container)"
              :disabled="!canItem(container, 'delete')"
            />
            <label :for="`resource-type${container.id}`">
              <svg-icon icon="checkmark-strait"></svg-icon>
              {{ container.name }}</label
            >
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              @click="setEditing"
            >
              {{ displayLabelName("global.footer.select") }}
            </button>
          </li>
          <li class="action" v-if="selectedContainers.length && editing">
            <button
              class="action-btn"
              :disabled="processing"
              @click="show = true"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                selectedContainers.length +
                "/" +
                listOfContainers.length +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("containers", "containers", "delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("containers", "containers", "delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import containersMixin from "@/services/mixins/containers/containers-mixin";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";
export default {
  name: "Containers",
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
      editing: false,
      selectedContainers: []
    };
  },
  created() {
    this.fetchContainers();
  },
  computed: {
    ...mapState("containers", ["containers", "selectedContainer"]),
    listOfContainers() {
      const query = this.searchQuery;
      if (this.containers && this.containers.length) {
        if (query && query.length > 1) {
          return this.containers.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.containers;
        }
      }
      return null;
    },
    pageName() {
      const { containerType } = this;
      if (containerType === "USER") {
        return "user-containers";
      } else if (containerType === "RESOURCE") {
        return "resource-containers";
      } else if (containerType === "LEVEL") {
        return "level-containers";
      } else if (containerType === "USER_MANAGER") {
        return "user-containers-manager";
      } else if (containerType === "RECEPTION_DESK") {
        return "reception-desk-containers";
      }
      return "";
    }
  },
  methods: {
    ...mapActions("containers", ["setSelectedContainer", "deleteContainer"]),
    ...mapActions("pagination", ["setPaginationData"]),
    editContainer(container) {
      if (this.canItem(container, "update")) {
        this.setSelectedContainer(container);
        setTimeout(() => {
          this.$router.push({ name: this.editRouteName });
        }, 0);
      }
    },
    selectContainer(container) {
      if (this.selectedContainers.includes(container.id)) {
        this.selectedContainers.splice(
          this.selectedContainers.indexOf(container.id),
          1
        );
      } else {
        this.selectedContainers.push(container.id);
      }
    },
    setEditing() {
      this.setSelectedContainer(null);
      this.editing = !this.editing;
      this.$router.push({ name: this.backLinkName });
    },
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedContainers.length; i++) {
        await this.deleteContainer(this.selectedContainers[i]);
      }

      this.processing = false;
      this.editing = false;
      this.selectedContainers = [];
      this.setPaginationData(null);
      this.setSelectedContainer(null);
      this.fetchContainers();
      this.$nextTick(() => {
        this.$router.push({ name: this.backLinkName });
      });
    }
  },
  watch: {
    containerTypeId() {
      this.fetchContainers();
    }
  },
  mixins: [containersMixin, paginationMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
